
import { routeToBusiness } from "@/helpers/routing"
import { mdiMapMarker, mdiMapMarkerOutline } from "@mdi/js"
export default {
    name: "BusinessSiteLocation",
    components: {
        StadiaMap: () =>
            // load StadiaMap only client-side
            process.client
                ? import("@/components/base/StadiaMap")
                : Promise.resolve({ render: (h) => h("div") }),
        ViewAllButton: () => import("@/components/base/button/ViewAllButton"),
    },
    props: {
        businessSite: {
            type: Object,
            default: null,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            mdiMapMarker,
            mdiMapMarkerOutline,
            mapLoaded: false,
        }
    },
    computed: {
        logoSrc() {
            return this.$getImage({
                image: this.businessSite.logo,
                preferedSize: "3",
            }).src
        },
        mapMarkers() {
            const logoSrc = this.logoSrc
            let markers = [
                {
                    lat: this.businessSite.primary_address.lat,
                    lng: this.businessSite.primary_address.lng,
                    iconSrc: logoSrc,
                    label: this.businessSite.name,
                },
            ]

            return markers
        },
        businessURL() {
            return routeToBusiness(this.businessSite)
        },
    },
}
